.col-1 {
    min-width: 224px;
    max-width: 224px;
}
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8 {
    text-align: center !important;
}
.col-actions {
    min-width: 216px;
    max-width: 216px;
}


tr{
    width:'100%';
    max-width:'100%';
    min-width:'100%';
}

table.contained {
    table-layout:fixed;
        width: 100%;
        border-collapse: collapse;
}

//fixed columns
th.col-actions-table {
    width: 192px;
}


//Buildings
th.col-buildings-timezone{
    width: 94px;
    max-width: 94px;
    min-width: 94px;
    padding: 13px 12px 12px 13px;
    div#header-content{
        position: relative;
        right: 12px;
    }
}

th.col-buildings-area{
    width: 107px;
    max-width: 107px;
    min-width: 107px;
    padding: 13px 12px 12px 13px;
    text-align: left;
    div#header-content{
        position: relative;
        right: 12px;
    }
}


//Floors
th.col-floors-max-allowed-people{
    width: 147px;
    max-width: 147px;
    min-width: 147px;
    padding: 13px 12px 12px 13px;
    div#header-content{
        position: relative;
        right: 12px;
    }
}